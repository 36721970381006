import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import bookingReducer from "./slices/booking";
import agentReducer from "./slices/agent";
import modalReducer from "./slices/modal";
import formLoaderReducer from "./slices/formLoader";


const appReducer = combineReducers({
	form: formReducer,

	auth: authReducer,
	modal: modalReducer,
	agent: agentReducer,
	booking: bookingReducer,
	formLoader: formLoaderReducer,

});

const rootReducer = (state: any, action: any) => {
	if (action.type === "auth/logout") state = {};
	return appReducer(state, action);
};

export default rootReducer;
