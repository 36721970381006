import AddAgentForm from "./AddAgentForm";
import { useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import UpdateAgentForm from "./UpdateAgentForm";
import Container from "@mui/material/Container";
import AgentService from "services/agent.service";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";

export default function AddAgent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const agent = useAppSelector((state) => state.agent.agent);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    data.status = data.status ? data.status : false;
    data.role = "agent";
    if (id) AgentService.updateAgent(`${id}`, data, navigate);
    else AgentService.addAgent(data, navigate);
  };

  return (
    <div>
      <Container>
        <GoBack path="/agents" title="Back to Agents" />
        <Banner heading={`${id ? "Update" : "Add"} Agent`} />
        <div className="form">
          {loading && <CircleLoader />}
          {agent?.data === "Not Found" ? (
            <p>Agent Not Found</p>
          ) : (
            <AddAgentForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateAgentForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
