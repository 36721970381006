import { required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./AddAgentForm";

export const fields: ReduxFormField[] = [
  {
    name: "status",
    label: "Status",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "first_name",
    label: "First Name",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "last_name",
    label: "Last Name",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "username",
    label: "Username",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "phone",
    label: "Phone",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "email",
    label: "Email",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "password",
    label: "Password",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
];
