import { fields } from ".";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useParams } from "react-router-dom";
function AddAgentForm({ handleSubmit }: any) {
  const { id } = useParams();
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        <br />
        <Button variant="contained" type="submit">
          {id ? "Update" : "Create"}
        </Button>
      </form>
    </div>
  );
}
export default reduxForm({ form: "AddAgentForm" })(AddAgentForm);
