import { AgentState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AgentState = {
  agents: [],
  agent: null,
  loading: true,
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addAgent: (state, action) => {
      state.agents.unshift(action.payload);
    },
    updateAgent: (state, action) => {
      const { id, agent } = action.payload;
      state.agents.every(({ _id }, i) => {
        if (id === _id) {
          state.agents[i] = agent;
          return false;
        }
        return true;
      });
    },
    setAgent: (state, action) => {
      state.agent = action.payload;
    },
    setAgents: (state, action) => {
      const agents = action.payload;
      let options: SelectOption[] = [];
      agents.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.agents = agents;
    },

    deleteAgent: (state, action) => {
      const id = action.payload;
      state.agents = state.agents.filter(({ _id }) => id !== _id);
    },
  },
});

const agentReducer = agentSlice.reducer;

export const agentActions = agentSlice.actions;
export default agentReducer;
