import "./Header.css";
import { links } from ".";
import { Fragment } from "react";
import { Container, Grid } from "@mui/material";
import logo from "assets/logo.png";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import BasicMenu from "components/atoms/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);

  if (!user || pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar">
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} lg={2}>
              <Link to="/bookings">
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    loading="lazy"
                    width={"60px"}
                  />
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} lg={10}>
              <ul>
                {links.map(({ to, text, active, options }, i) => (
                  <Fragment key={i}>
                    {options ? (
                      <li>
                        <BasicMenu
                          avatar={false}
                          color="#FFFFFF"
                          list={options.map(({ value, label }) => ({
                            text: label,
                            onClick: () => navigate(value),
                          }))}
                        >
                          {text}
                        </BasicMenu>
                      </li>
                    ) : (
                      <li key={i}>
                        <Link
                          to={to}
                          className={pathname.includes(active) ? "active" : ""}
                        >
                          {text}
                        </Link>
                      </li>
                    )}
                  </Fragment>
                ))}
                <li>
                  <div style={{ padding: "10px 0" }}>
                    <BasicMenu
                      avatar={true}
                      list={[
                        {
                          text: "Logout",
                          onClick: () => AuthService.logout(),
                        },
                      ]}
                    >
                      A
                    </BasicMenu>
                  </div>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
    </header>
  );
}
