import AddAgent from "pages/AddAgent";
import Agents from "pages/Agents";
import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const Bookings = lazy(() => import("pages/Booking"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [
  { path: "/bookings", element: <Bookings /> },

  { path: "/agents", element: <Agents /> },
  { path: "/add-agent", element: <AddAgent /> },
  { path: "/update-agent/:id", element: <AddAgent /> },
];
