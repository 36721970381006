import { useEffect } from "react";
import Chip from "components/atoms/Chip";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentService from "services/agent.service";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Stack,
} from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { MODAL, modalActions } from "redux/slices/modal";
import SubscriptionService from "services/subscription.service";

export default function AgentList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const agents = useAppSelector((state) => state.agent.agents);
  const loading = useAppSelector((state) => state.agent.loading);

  useEffect(() => {
    AgentService.getAllAgents();
  }, []);

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        sx={{
          minWidth: "100%",
          borderSpacing: "0 10px",
          borderBottomWidth: "0px",
          borderCollapse: "separate",
        }}
      >
        <TableLoadingWrapper
          coloumns={4}
          loading={loading}
          length={agents.length}
          message="Our services are not available in any agent yet"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Password</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents.map((agent) => (
              <StyledTableRow key={agent._id}>
                <StyledTableCell>{`${agent.first_name} ${agent.last_name}`}</StyledTableCell>
                <StyledTableCell>{agent.email}</StyledTableCell>
                <StyledTableCell>{agent.password_text}</StyledTableCell>
                <StyledTableCell>{agent.phone}</StyledTableCell>
                <StyledTableCell>
                  <Chip status={agent.status ? "Online" : "Offline"} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Stack direction="row" justifyContent="center">
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => navigate(`/update-agent/${agent._id}`)}
                    >
                      <EditOutlined />
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        SubscriptionService.subscribe(() => {
                          AgentService.deleteAgent(agent._id);
                        });
                        dispatch(
                          modalActions.openModal({
                            width: "500px",
                            type: MODAL.CONFIRMATION_FORM,
                            data: {
                              heading: "Delete Agent",
                              message: `Do you really want to delete Agent ${agent.first_name} ${agent.last_name}?`,
                            },
                          })
                        );
                      }}
                    >
                      <DeleteOutline color="error" />
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TableLoadingWrapper>
      </Table>
    </TableContainer>
  );
}
