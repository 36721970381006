import AgentList from "./AgentsList";
import { Container } from "@mui/material";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
export default function Agent() {
	const navigate = useNavigate();
	return (
		<div>
			<Container maxWidth="lg">
				<Banner heading="Agent">
					<Button
						variant="contained"
						onClick={() => navigate("/add-agent")}
					>
						Add Agent
					</Button>
				</Banner>
				<AgentList />
			</Container>
		</div>
	);
}
