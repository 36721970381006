import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import AgentService from "services/agent.service";
import { agentActions } from "redux/slices/agent";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function UpdateAgentForm({ id }: any) {
  const form = "AddAgentForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const agent = useAppSelector((state) => state.agent.agent);

  useEffect(() => {
    AgentService.getAgent(`${id}`);
    return () => {
      dispatch(agentActions.setAgent(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!agent || agent?.data === "Not Found") return;
    const { first_name, last_name, username, phone, email, password, status } =
      agent;
    dispatch(change(form, "first_name", first_name));
    dispatch(change(form, "last_name", last_name));
    dispatch(change(form, "username", username));
    dispatch(change(form, "phone", phone));
    dispatch(change(form, "email", email));
    dispatch(change(form, "password", password));
    dispatch(change(form, "status", status));
  }, [agent, navigate, dispatch]);

  return null;
}
