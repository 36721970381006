import http from "./http.service";
import Promisable from "./promisable.service";
import { agentActions } from "redux/slices/agent";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { modalActions } from "redux/slices/modal";

const url = "/admin/agent";

const AgentService = {
  addAgent: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { agent } = success.data.data;
      dispatch?.(agentActions.addAgent(agent));
      navigate?.("/agents");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateAgent: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { agent } = success.data.data;
      dispatch?.(agentActions.updateAgent({ id, agent }));
      navigate?.("/agents");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAgent: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { agent } = success.data.data;
      dispatch?.(agentActions.setAgent(agent));
    } else dispatch?.(agentActions.setAgent({ data: "Not Found" }));

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAllAgents: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(agentActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { agents } = success.data.data;
      dispatch?.(agentActions.setAgents(agents));
    }

    dispatch?.(agentActions.setLoading(false));
    return [success, error];
  },

  deleteAgent: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
			dispatch?.(agentActions.deleteAgent(id));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default AgentService;
