import { BookingState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: BookingState = {
  bookings: [],
  booking: null,
  loading: true,
  bookingOptions: [],
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clear: (state) => {
      state.bookingOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addBooking: (state, action) => {
      state.bookings.unshift(action.payload);
    },
    updateBooking: (state, action) => {
      const { id, booking } = action.payload;
      state.bookings.every(({ _id }, i) => {
        if (id === _id) {
          state.bookings[i] = booking;
          return false;
        }
        return true;
      });
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setBookings: (state, action) => {
      const bookings = action.payload;
      let options: SelectOption[] = [];
      bookings.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.bookings = bookings;
      state.bookingOptions = options;
    },

    deleteBooking: (state, action) => {
      const id = action.payload;
      state.bookings = state.bookings.filter(({ _id }) => id !== _id);
    },
  },
});

const bookingReducer = bookingSlice.reducer;

export const bookingActions = bookingSlice.actions;
export default bookingReducer;
