import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      light: "#71296f",
      main: "#71296f",
      dark: "#71296f",
    },
    secondary: {
      light: "#debd5f",
      main: "#debd5f",
      dark: "#debd5f",
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export default Theme;
